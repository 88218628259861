import {ChatPaneMin} from './chat/ChatPaneMin'
import { Title} from '@mantine/core'
import { useState, useEffect } from 'react'
import { HeaderResponsive } from './headers/HeaderMin'
import styles from "../styles/Search.module.css";
import { FeedbackModal } from './FeedbackModal';
import { useContext } from 'react'
import { PageContext } from '../contexts/pageContext';
//import uuid
import { v4 as uuidv4 } from 'uuid';
import { ShareButton } from './ShareButton'
import { BetterButton } from './utils/Buttons'
import HistorySidebar from './HistorySidebar';
import { MobileWrapper } from './MobileWrapper';
import {supabase_public} from '../lib/supabaseClient'
import AddToHomeScreenBanner from './AddToHomeScreenBanner';
import posthog from 'posthog-js';
import { HandlerError } from '@auth0/nextjs-auth0';
import { AuthShell } from './shell/AuthShell';
const API_URL = process.env.NEXT_PUBLIC_API_URL;
export function MinApp(props: any) {
    const [currentRecs, setCurrentRecs] = useState(props.recs || [])
    const [isMobile, setIsMobile] = useState(false);
    const [topic, setTopic] = useState(props.topic || "")
    const [topicSelected, setTopicSelected] = useState(props.topicSelected || false)
    const [location, setLocation] = useState("")
    const [locationSelected, setLocationSelected] = useState(false)
    const [coords, setCoords] = useState({lat: 0, lng: 0})
    const [makeProductsAtChat, setMakeProductsAtChat] = useState(true)

    const lastChat = props.chats ? props.chats[props.chats.length - 1] : undefined;
    const [questionsList, setQuestionsList] = useState(lastChat ? lastChat.questions : []);
    const [progressMessages, setProgressMessages] = useState(lastChat ? lastChat.progressMessages : []);
    const recs = lastChat ? lastChat.recs : [];
    const [passedProducts, setPassedProducts] = useState(recs);
    const [uid, setUser] = useState(null)
    const [session, setSession] = useState(null)

    //const [passedProducts, setPassedProducts] = useState(props.chats[props.chats.length - 1].recs || [])
    const [sessionUUID, setSessionUUID] = useState(props.threadId || uuidv4())
    const [feedbackOpened, setFeedbackOpened] = useState(false)
    const [numberOfInlineProducts, setNumberOfInlineProducts] = useState(1)
    const context = useContext(PageContext)
    const [loggedinusername, setLoggedInUsername] = useState("")
    const [showAuthPrompt, setShowAuthPrompt] = useState(false);
    const [profile, setProfile] = useState(null);
    const [askedQuestions, setAskedQuestions] = useState(lastChat ? lastChat.askedQuestions : [])
    const [customFilters, setCustomFilters] = useState([])
    const [photo, setPhoto] = useState(null)
    const forceCheckout = Math.random()  < 0.25


    function handleFeedback(state) {
        setFeedbackOpened(state)
        posthog.capture('feedback opened')
    }

    async function getProfile(uid) {
        try {
            const response = await fetch(API_URL + '/getUserProfile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ uid: uid })
            });

            const result = await response.json();

            // Handle the result here
            if (result.username) {
                setLoggedInUsername(result.username); // Assuming setLoggedInUsername is not async
                setProfile(result); // Assuming setProfile is not async
                setCustomFilters(result.custom_filters)
            }
        } catch (error) {
            // Handle the error here
            console.log(error);
        }
    }

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, []); // Empty dependency array ensures this runs once after initial render



    useEffect(() => {
        async function fetchAllData() {
            const { data, error } = await supabase_public.auth.getSession();
            if (error) {
                console.log("error retrieving session");
                return;
            }
            // Set the session data
            await setSession(data);
            // Assuming the structure of data is { session: { user: { email } } }
            const email = data?.session?.user?.email;
            const id = data?.session?.user?.id;
            // console.log("User logged in with id", id)
            await setUser(id)
            if (id && id !== uid) {
                await getProfile(id);
            }

        }
        fetchAllData();

    }, []); // Empty dependency array ensures this runs once on mount

    useEffect(() => {
        async function Profile() {
            if (uid) {
                await getProfile(uid);
            }
        }
        Profile();
    }, [uid]); // Empty dependency array ensures this runs once on mount


    supabase_public.auth.onAuthStateChange( async (event, session) => {
        // const hostname = window.location.hostname;
        // const domain = `domain=${hostname};`;

        if (event === 'SIGNED_OUT' ) {
          // delete cookies on sign out
          const expires = new Date(0).toUTCString()
          document.cookie = `my-access-token=; path=/; expires=${expires}; SameSite=Lax; secure`
          document.cookie = `my-refresh-token=; path=/; expires=${expires}; SameSite=Lax; secure`


        } else if (event === 'SIGNED_IN') {
          const maxAge =  24 * 60 * 60; //force redeloy
          document.cookie = `my-access-token=${session.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
          document.cookie = `my-refresh-token=${session.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
        //   if someone is user is not logged in but has a session
          if (! uid && session ) {
            await setSession(session)
            const id = session?.user?.id
            if (id) {
                setUser(id)
            }
          }
        } else if ( event === 'TOKEN_REFRESHED') {
            const maxAge =  24 * 60 * 60; //force redeloy
            document.cookie = `my-access-token=${session.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
            document.cookie = `my-refresh-token=${session.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`
          //   if someone is user is not logged in but has a session
        }
    })



    function makeInput(userInput) {
        return "User wants to buy: " + userInput;
    }

    useEffect(() => {
        // Get the current URL
        const url = new URL(window.location.href);
        // Get the search parameter from the URL
        const searchQuery = url.searchParams.get('search');
        const prefill = url.searchParams.get('prefill');
        // If there's a search query, update the topic
        if (searchQuery) {
            setTopic(searchQuery);
            setTopicSelected(true)
        }
        if (prefill) {
            setTopic(prefill)
        }
    }, []);  // Empty dependency array means this useEffect runs once, similar to componentDidMount


    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 925){
                setIsMobile(true)
                setNumberOfInlineProducts(1)
            } else {
                setIsMobile(false)
                setNumberOfInlineProducts(4)
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
    <>
    <div className={styles.padsir} id="claros-container" >
    {/* topic not selected yet */}
    {!topicSelected && (
    <div style={{display: 'block', height: 'auto'}}>
    {context.isMainPage && (
        <>
        <AuthShell sessionUUID={sessionUUID}
            loggedInUsername={loggedinusername}
            showAuthPrompt={showAuthPrompt}
            setShowAuthPrompt={setShowAuthPrompt}
            topicSelected={topicSelected}
            feedbackOpened={feedbackOpened}
            userid={uid}
            setFeedbackOpened={handleFeedback}
            profile={profile}>
                <div className="w-full">
                <HeaderResponsive logo={context.logoUrl}
                    topic={topic}
                    updateTopic={setTopic}
                    topicSelected={topicSelected}
                    setTopicSelected={setTopicSelected}
                    location={location}
                    updateLocation={setLocation}
                    locationSelected={locationSelected}
                    coords={coords}
                    setCoords={setCoords}
                    setLocationSelected={setLocationSelected}
                    isMobile={isMobile}
                    website={context.website}
                    sessionUUID={sessionUUID}
                    askedQuestions={askedQuestions}
                    setAskedQuestions={setAskedQuestions}
                    setPhoto={setPhoto}
                    photo={photo}
                    customFilters={customFilters}
                    setCustomFilters={setCustomFilters}
                    />

                </div>
        </AuthShell>
        </>
    )}
    {!context.isMainPage && (
        <HeaderResponsive logo={context.logoUrl}
            topic={topic}
            updateTopic={setTopic}
            topicSelected={topicSelected}
            setTopicSelected={setTopicSelected}
            location={location}
            updateLocation={setLocation}
            locationSelected={locationSelected}
            coords={coords}
            setCoords={setCoords}
            setLocationSelected={setLocationSelected}
            isMobile={isMobile}
            website={context.website}
            askedQuestions={askedQuestions}
            setAskedQuestions={setAskedQuestions}
            setPhoto={setPhoto}
            photo={photo}
            customFilters={customFilters}
            setCustomFilters={setCustomFilters}
            />
    )}
    </div>
    )}
   { topicSelected  && (
        props.chats && props.chats.length > 0 ? (
            context.isMainPage ? (
                <>
                <AuthShell sessionUUID={sessionUUID}
                    loggedInUsername={loggedinusername}
                    showAuthPrompt={showAuthPrompt}
                    setShowAuthPrompt={setShowAuthPrompt}
                    topicSelected={topicSelected}
                    feedbackOpened={feedbackOpened}
                    userid={uid}
                    setFeedbackOpened={handleFeedback}
                    profile={profile}>
                    <div className="overflow-y-auto bg-bgwhite max-w-screen max-h-[90vh]">
                        <ChatPaneMin updateRecs={setCurrentRecs}
                            topic={topic}
                            topicSelected={topicSelected}
                            makeProductsAtChat={makeProductsAtChat}
                            setPassedProducts={setPassedProducts}
                            numberOfInlineProducts={4}
                            showmoremodal={true}
                            recs={currentRecs}
                            passedProducts={passedProducts}
                            isMobile={isMobile}
                            sessionUUID={sessionUUID}
                            feedbackOpened={feedbackOpened}
                            setFeedbackOpened={handleFeedback}
                            session={session}
                            inlineProducts={props.products}
                            qapair={props.chats[props.chats.length - 1].history}
                            options={props.chats[props.chats.length - 1].options}
                            chats={props.chats}
                            showAuthPrompt={showAuthPrompt}
                            setShowAuthPrompt={setShowAuthPrompt}
                            profile={profile}
                            uid={uid}
                            askedQuestions={askedQuestions}
                            setAskedQuestions={setAskedQuestions}
                            progressMessages={progressMessages}
                            questionsList={questionsList}
                            photo={photo}
                            customFilters={customFilters}
                            forceCheckout={forceCheckout}
                        />
                    </div>
                    </AuthShell>
                        {/* <div className="overflow-y-auto max-h-[99vh] "> */}
                </>
            ) : (
                <div className="overflow-hidden">
                <ChatPaneMin updateRecs={setCurrentRecs}
                        topic={topic}
                        topicSelected={topicSelected}
                        makeProductsAtChat={makeProductsAtChat}
                        setPassedProducts={setPassedProducts}
                        numberOfInlineProducts={4}
                        showmoremodal={true}
                        recs= {currentRecs}
                        passedProducts={passedProducts}
                        isMobile={isMobile}
                        sessionUUID={sessionUUID}
                        feedbackOpened={feedbackOpened}
                        setFeedbackOpened={handleFeedback}
                        session={session}
                        inlineProducts={props.products}
                        qapair={props.chats[props.chats.length - 1].history}
                        options={props.chats[props.chats.length - 1].options}
                        chats={props.chats}
                        showAuthPrompt={showAuthPrompt}
                        setShowAuthPrompt={setShowAuthPrompt}
                        profile={profile}
                        uid={uid}
                        askedQuestions={askedQuestions}
                        setAskedQuestions={setAskedQuestions}
                        progressMessages={progressMessages}
                        questionsList={questionsList}
                        photo={photo}
                        forceCheckout={forceCheckout}
                        />
                </div>
            )
        ) : (
            context.isMainPage ? (
                    <AuthShell loggedInUsername={loggedinusername} userid={uid} sessionUUID={sessionUUID} showAuthPrompt={showAuthPrompt} setShowAuthPrompt={setShowAuthPrompt} topicSelected={topicSelected} feedbackOpened={feedbackOpened} setFeedbackOpened={handleFeedback} profile={profile}>
                    <div className="overflow-y-auto bg-bgwhite">
                    <ChatPaneMin updateRecs={setCurrentRecs}
                            topic={topic}
                            topicSelected={topicSelected}
                            makeProductsAtChat={makeProductsAtChat}
                            setPassedProducts={setPassedProducts}
                            numberOfInlineProducts={4}
                            showmoremodal={true}
                            recs= {currentRecs}
                            passedProducts={passedProducts}
                            isMobile={isMobile}
                            sessionUUID={sessionUUID}
                            feedbackOpened={feedbackOpened}
                            setFeedbackOpened={handleFeedback}
                            session={session}
                            showAuthPrompt={showAuthPrompt}
                            setShowAuthPrompt={setShowAuthPrompt}
                            profile={profile}
                            uid={uid}
                            askedQuestions={askedQuestions}
                            setAskedQuestions={setAskedQuestions}
                            progressMessages={progressMessages}
                            questionsList={questionsList}
                            photo={photo}
                            customFilters={customFilters}
                            forceCheckout={forceCheckout}
                            />
                    </div>
                    </AuthShell>
            ) : (
            <div className="overflow-y-auto">
                <ChatPaneMin updateRecs={setCurrentRecs}
                        topic={topic}
                        topicSelected={topicSelected}
                        makeProductsAtChat={makeProductsAtChat}
                        setPassedProducts={setPassedProducts}
                        numberOfInlineProducts={4}
                        showmoremodal={true}
                        recs= {currentRecs}
                        passedProducts={passedProducts}
                        isMobile={isMobile}
                        sessionUUID={sessionUUID}
                        feedbackOpened={feedbackOpened}
                        setFeedbackOpened={handleFeedback}
                        session={session}
                        showAuthPrompt={showAuthPrompt}
                        setShowAuthPrompt={setShowAuthPrompt}
                        profile={profile}
                        uid={uid}
                        askedQuestions={askedQuestions}
                        setAskedQuestions={setAskedQuestions}
                        progressMessages={progressMessages}
                        questionsList={questionsList}
                        photo={photo}
                        customFilters={customFilters}
                        forceCheckout={forceCheckout}
                        />
                </div>
        ))
    )}

    {feedbackOpened && <FeedbackModal opened={feedbackOpened} setOpened={setFeedbackOpened}  sessionUUID={sessionUUID} /> }

    </div>
    </>
  )
}